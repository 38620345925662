import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, Personnel } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  personnels: { items: Personnel[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class PersonnelsGQL extends Query<Response, GraphQLParams<'personnel'>> {
  document = gql(config.personnel.query);
}
